<template>
  <div class="width-full height-full">
    <v-parallax class="height-full width-full m-0">
<!--                src="../../public/static/images/back.jpg"-->
<!--    >-->
      <v-row
        align="center"
        justify="center"
        style="background-color: rgba(0, 0, 0, 0.2); backdrop-filter: blur(20px); padding: 0"
      >
        <v-col
          class="text-center"
          cols="12"
        >
          <v-progress-circular
            :rotate="360"
            :size="100"
            :width="5"
            :value="value"
            color="teal"
          >
            <v-btn
              outlined
              fab
              x-large
              color="teal"
              :disabled="isLoading"
              class="size-100x100">
              <h2>
                Loan
              </h2>
            </v-btn>
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-parallax>
  </div>
</template>

<script>
import { OriginationApi } from '../api/application'
// import { LeadApi } from '../api/lead'
import { MaintenanceApi } from '../api/maintenance'
import { Tool, DataType } from '../js/core'

const tool = new Tool()
export default {
  data () {
    return {
      route: '/notification/-100',
      isLoading: true,
      value: 0,
      size: 0,
      loanAmount: '',
      loanDetail: {},
      loanId: '',
      domainList: DataType.Domain,
      domainId: '',
      domain: '',
      url: '',
      currentUrl: ''
    }
  },
  methods: {
    getLoanId () {
      return tool.getCookie(DataType.COOKIE_KEY.LOAN_ID)
    },
    getCurrentUrl () {
      this.currentUrl = window.location.href
    },
    progress () {
      const _this = this
      const interval = setInterval(function () {
        if (_this.value < _this.size) {
          _this.value++
        } else if (_this.value === 100) {
          _this.isLoading = false
          clearInterval(interval)
        }
      }, 50)
    },
    error () {
      console.log('Error')
    }
  },
  async created () {
    let address = ''
    if (tool.isPC()) {
      address = '/instant'
    }
    OriginationApi.getIp(function (result) {
      localStorage.setItem('IP', result.ip)
    })

    // LeadApi.updateLeadFeatureData({
    //   leadId: tool.getCookie('lId'),
    //   featureKey: 'CLICK_REDIRECT',
    //   featureValue: 1,
    //   sectionKey: 'TRACKER'
    // })
    this.size = 10
    this.progress()
    const _this = this
    if (tool.isEmpty(this.getLoanId())) {
      const cryptLoanId = tool.getQueryVariable('nid')
      if (tool.isNotEmpty(cryptLoanId)) {
        await OriginationApi.getLoanIdDecrypt({ loanId: cryptLoanId }, function (result) {
          const params = result.split('#')
          _this.loanId = params[0]
          tool.setItem('LI', params[0])
          tool.setItem('AT', params[1])
        })
      } else {
        console.log('ERROR')
      }
    } else {
      _this.loanId = this.getLoanId()
    }
    try {
      await OriginationApi.getPersonalDataByLoanId({
        loanId: _this.loanId
      }, function (result) {
        localStorage.setItem(DataType.COOKIE_KEY.LOAN_ID, _this.loanId)
        localStorage.setItem(DataType.COOKIE_KEY.PERSONAL, JSON.stringify(result))
        localStorage.setItem(DataType.COOKIE_KEY.WSID, tool.md5(_this.loanId))
        _this.size = 20
      }, function (res) {
        _this.error()
      })
    } catch (error) {
      _this.url = window.location.href
      if (tool.isNotEmpty(_this.url)) {
        _this.domain = _this.url.split('//')[1].split('.')[1]
        if (tool.isNotEmpty(_this.domain)) {
          const obtainedDomain = _this.domainList.filter(item => {
            return item.domain === _this.domain
          })
          _this.domainId = obtainedDomain[0].id
          if (tool.isNotEmpty(_this.domainId)) {
            await MaintenanceApi.getPortfolioDetailByPortfolioId({ portfolioId: _this.domainId }, function (result) {
              localStorage.setItem(DataType.COOKIE_KEY.PORTFOLIO, JSON.stringify(result))
              _this.$store.commit('setPortfolioJson', result)
            })
            this.$router.push(address + '/notification/-100')
          } else {
            _this.$store.commit('setPortfolioJson', null)
            this.$router.push('/notification/-101')
          }
        }
      }
    }
    await OriginationApi.getLoanById({
      loanId: _this.loanId,
      feature: 'STATUS,PAYMENT_OPTION'
    }, function (result) {
      localStorage.setItem(DataType.COOKIE_KEY.CUSTOMER_ID, result.customerId)
      localStorage.setItem(DataType.COOKIE_KEY.LOAN_TYPE, result.loanType)
      localStorage.setItem(DataType.COOKIE_KEY.CATEGORY, result.category)
      if (result.flags === 1 && result.priority > 900) {
        localStorage.setItem('PRIORITY', result.priority)
      }
      _this.loanDetail = result
    }, function (res) {
      _this.$router.push(_this.route)
    }, function () {
      _this.error()
    })
    if (DataType.LoanTypeEnum.MULTIPLE_ADVANCE_LOAN.value === _this.loanDetail.loanType) {
      await OriginationApi.initSlaveLoan({ parentId: _this.loanId })
    }
    if (DataType.LoanTypeEnum.CREDIT_LOAN.value === _this.loanDetail.loanType || DataType.LoanTypeEnum.SLAVE_LOAN.value === _this.loanDetail.loanType) {
      return false
    }
    _this.size = 40

    await MaintenanceApi.getPortfolioDetailByPortfolioId({ portfolioId: _this.loanDetail.portfolioId }, function (result) {
      localStorage.setItem(DataType.COOKIE_KEY.PORTFOLIO, JSON.stringify(result))
      _this.$store.commit('setPortfolioJson', result)
    })
    if (!(_this.loanDetail.loanStatus === DataType.LoanStatus.CUSTOMER_REVIEW.value ||
      _this.loanDetail.loanStatus === DataType.LoanStatus.PENDING_ORIGINATION.value)) {
      if (_this.loanDetail.loanStatus === 20002) {
        await this.$router.push(address + '/notification/-100')
      }
      if (_this.loanDetail.loanStatus === 20004) {
        await this.$router.push(address + '/notification/202')
      }
      if (_this.loanDetail.loanStatus > 30001 && _this.loanDetail.loanStatus < 40000) {
        await this.$router.push(address + '/notification/200')
      }
      return false
    }
    if (_this.loanDetail.loanStatus === DataType.LoanStatus.PENDING_ORIGINATION.value) {
      await OriginationApi.updateOrigination({
        feature: 'loan-status',
        data: {
          loanId: this.loanId,
          loanStatus: DataType.LoanStatus.CUSTOMER_REVIEW.value
        }
      })
    }
    localStorage.setItem(DataType.COOKIE_KEY.PORTFOLIO_ID, _this.loanDetail.portfolioId)
    // Update Loan program
    if (tool.isEmpty(_this.loanDetail.program)) {
      await OriginationApi.updateOrigination({
        feature: 'program',
        data: {
          loanId: _this.loanId,
          portfolioId: _this.loanDetail.portfolioId
        }
      }, function (result) {
      })
    }
    _this.size = 100
    // LeadApi.updateLeadFeatureData({
    //   leadId: tool.getCookie('lId'),
    //   featureKey: 'CLICK_ONLINE',
    //   featureValue: 1,
    //   sectionKey: 'TRACKER'
    // })
    if (tool.isNotEmpty(this.loanDetail.loanAmount) && this.loanDetail.loanAmount >= 200) {
      OriginationApi.enteredAndLeft(
        _this.loanId,
        null,
        DataType.PAGES.LOAN_AMOUNT.step,
        localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
      )
      if (tool.isPC()) {
        this.$router.push(DataType.PAGES.LOAN_AMOUNT.addressPC)
      } else {
        this.$router.push(DataType.PAGES.LOAN_AMOUNT.address)
      }
    } else {
      OriginationApi.enteredAndLeft(
        _this.loanId,
        null,
        DataType.PAGES.WELCOME.step,
        localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID))
      this.$router.push(DataType.PAGES.WELCOME.addressPC)
      // if (tool.isPC()) {
      //   this.$router.push(DataType.PAGES.WELCOME.addressPC)
      // } else {
      //   this.$router.push(DataType.PAGES.WELCOME.address)
      // }
    }
  }
}
</script>

<style lang="css">
  .v-parallax__image {
    transform: translate(-50%, 0) !important
  }

  .v-parallax__content {
    padding: 0px !important;
  }
</style>
